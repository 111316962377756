// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// page styles
import chiliCookOffPrizesStyle from "assets/jss/material-kit-pro-react/views/chiliCookOffPageSections/chiliCookOffPrizesStyle.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
// core components
import Button from "components/CustomButtons/Button.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
// gatsby libraries
import { graphql, Link, useStaticQuery } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

const useStyles = makeStyles(chiliCookOffPrizesStyle)

export default function ChiliCookOffPrizes() {
  const {
    chiliCookOffPrizesBackground,
    chiliCookOffPrizes1,
  } = useStaticQuery(graphql`
    query chiliCookOffPrizes {
      chiliCookOffPrizesBackground: file(
        relativePath: { eq: "chili-cook-off/chili-cook-off-16.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      chiliCookOffPrizes1: file(
        relativePath: { eq: "chili-cook-off/chili-cook-off-15.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <GatsbyImage
        className={classes.backgroundImage}
        image={chiliCookOffPrizesBackground.childImageSharp.gatsbyImageData}
        alt="Event Photo"
      />
      <div className={classes.backgroundImageText}>
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <Card className={classes.prizeCard}>
              <CardBody>
                <GridContainer
                  justifyContent="center"
                  alignItems="center"
                  className={classes.judgingCard}
                >
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    className={classes.aboutItem}
                  >
                    <GatsbyImage
                      className={classes.judgingIconWrapper}
                      image={
                        chiliCookOffPrizes1.childImageSharp.gatsbyImageData
                      }
                      objectPosition={"top"}
                      alt="Chili Cook-Off Wood Carved Trophy"
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={8}
                    className={classes.aboutItem}
                  >
                    <h2 className={classes.title}>Chili Cook-Off Prizes</h2>
                    <p className={classes.servicesAboutListTitle}>
                      Thanks to the{" "}
                      <OutboundLink href="http://www.rockrivervalleycarvers.com/">
                        Rock River Valley Wood Carvers
                      </OutboundLink>{" "}
                      for supplying the awesome Chilimania trophies! Trophies
                      are awarded to the winners of all categories listed below;
                      all cook-off contestants receive a gift bag!
                    </p>
                    <h4 className={classes.cardTitle}>Best Chili:</h4>
                    <p className={classes.description}>
                      <b>1st place:</b> $350 + trophy
                      <br />
                      <b>2nd place:</b> $250 + trophy
                      <br />
                      <b>3rd place:</b> $125 + trophy
                    </p>
                    <h4 className={classes.cardTitle}>Best Booth Theme:</h4>
                    <p className={classes.description}>
                      <b>1st place:</b> $100 + trophy
                    </p>
                    <h4 className={classes.cardTitle}>
                      People's Choice Chili:
                    </h4>
                    <p className={classes.description}>
                      <b>1st place:</b> $100 + trophy
                    </p>
                    <Link to="/past-winners">
                      <Button
                        color="primary"
                        className={classes.cookOffPrizesButton}
                      >
                        View Past Winners
                      </Button>
                    </Link>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridContainer>
        </div>
      </div>
    </div>
  )
}
